import CustomerFieldType from '@/models/CustomerFieldType'

export default class CustomerFieldTypeRepo {
	static endpoint = '/api/customer-field-type'

	static fetchById (customerFieldTypeId: number) {
		return CustomerFieldType.api().get(`${this.endpoint}?customer-field-type-id=${customerFieldTypeId}`)
	}

	static fetchAll () {
		return CustomerFieldType.api().get(`${this.endpoint}`)
	}
}
