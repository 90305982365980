import ProviderServiceRegion from '@/models/ProviderServiceRegion'

export default class ProviderServiceRegionRepo {
	static endpoint = '/api/provider-service-region'

	static fetchAll () {
		return ProviderServiceRegion.api().get(`${this.endpoint}`)
	}

	static fetchByServiceProviderId (serviceProviderId: number) {
		return ProviderServiceRegion.api().get(`${this.endpoint}?service-provider-id=${serviceProviderId}`)
	}
}
