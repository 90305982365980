<script>
import QrCode from '@/components/shared/QrCode'
export default {
	name: 'BrowserAlert',
	props: {
		displayCard: {
			type: Boolean,
			default: false
		},
		showDetails: {
			type: Boolean,
			default: false
		}
	},
	components: { QrCode },
	computed: {
		device: function () {
			return this.$store.getters['cms/getDevice']
		}
	}
}
</script>

<template>
	<div class="browser-alert" v-if="!device.isCompatibleDevice || device.isDesktop">
		<qr-code v-if="device.isDesktop" />
		<div v-else :class="{ 'browser-alert__card' : displayCard }">
			<div class="browser-alert__header" :class="{ 'browser-alert__header--row' : !showDetails }">
				<font-awesome-icon class="browser-alert__icon" :icon="['fas', 'exclamation-circle']"
					:class="{ 'browser-alert__icon--danger browser-alert__icon--row' : !showDetails }" />
				<div class="browser-alert__title" :class="{ 'browser-alert__title--small' : !showDetails }">
					<span v-html="$t('commonMsg.browserNotSupported')"/>
					<span v-if="!showDetails" v-html="$t('commonMsg.switchBrowser.compact', { browser: device.nativeBrowser })"></span>
				</div>
			</div>
			<div class="browser-alert__content" v-show="showDetails">
				<span class="browser-alert__content--padding" v-html="$t('commonMsg.switchBrowser.detailed', { browser: device.nativeBrowser })"></span>
				<div class="browser-alert__callout">
					<font-awesome-icon class="browser-alert__icon--muted browser-alert__icon--small browser-alert__icon--row"
						:icon="['fas','external-link-alt']" />
					<p v-html="$t('commonMsg.switchBrowser.callout')"></p>
				</div>
				<slot />
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.browser-alert {
	background-color: var(--surface-colour);
	color: var(--on-surface);
	height: 100%;
	width: 100%;
	&__card {
		border-radius: var(--border-radius);
		margin: 2rem auto;
		max-width: 500px;
		@media (max-width: 450px) {
			border-radius: 0;
		}
	}
	&__header {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 auto;
		padding: 2rem 2rem 1rem 2rem;
		max-width: var(--container-width);
		width: 100%;
		&--row {
			flex-direction: row;
			justify-content: flex-start;
			padding: 1rem 1.5rem;
		}
	}
	&__title {
		font-size: var(--font-size-subheading);
		font-weight: var(--font-weight-subheading);
		letter-spacing: var(--letter-spacing-expanded);
		margin-bottom: 1rem;
		@media (max-width: 450px) {
			font-size: 5vw;
		}
		&--small {
			margin-bottom: 0;
			font-size: var(--font-size-caption);
			font-weight: var(--font-weight-caption);
			@media (max-width: 600px) {
				font-size: 0.9rem;
			}
		}
	}
	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-bottom: 2rem;
		@media (max-width: 450px) {
			font-size: 4vw;
		}
		&--padding {
			padding: 0 2rem 1rem 2rem;
		}
	}
	&__icon {
		font-size: 3rem;
		margin-bottom: 1rem;
		&--danger {
			color: var(--error-colour);
		}
		&--muted {
			color: #6f6f6f;
		}
		&--row {
			margin-bottom: 0;
			margin-right: 2rem;
			@media (max-width: 600px) {
				margin-right: 1rem;
			}
		}
		&--small {
			font-size: 1.75rem;
		}
	}
	&__callout {
		align-items: center;
		background-color: #FFF6A6;
		color: #3b3b3b;
		display: flex;
		font-size: var(--font-size-caption);
		padding: 1rem 2rem;
	}
}
</style>
