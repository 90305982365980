import Agreement from '@/models/Agreement'

export default class AgreementRepo {
	static endpoint = '/api/agreement'

	static fetchAll () {
		return Agreement.api().get(this.endpoint)
	}

	static fetchByLocation (location) {
		return Agreement.api().get(`${this.endpoint}?location=${location}`)
	}
}
