import CustomContent from '@/models/CustomContent'
import ContentGroupItem from '@/models/ContentGroupItem'

export default class CustomContentRepo {
	static endpoint = '/api/custom-content'

	static fetchById (customContentId: number) {
		return CustomContent.api().get(`${this.endpoint}?custom-content-id=${customContentId}`)
	}

	static async fetchForContentGroups () {
		let fetchRequests = []
		ContentGroupItem.query().withAll().get()
			.forEach((contentGroupItem) => {
				// @ts-ignore
				const contentItem = contentGroupItem.contentItem
				if (contentItem && contentItem.contentType === 'CUSTOM_CONTENT') {
					// @ts-ignore
					fetchRequests.push(CustomContentRepo.fetchById(contentItem.customContentId))
				}
			})
		await Promise.all(fetchRequests)
	}
}
