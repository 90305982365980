import ContentItem from '@/models/ContentItem'

export default class ContentItemRepo {
	static endpoint = '/api/content-item'

	static fetchByCardId (cardId: number) {
		return ContentItem.api().get(`${this.endpoint}?card-id=${cardId}`)
	}

	static fetchByContentGroupId (contentGroupId: number) {
		return ContentItem.api().get(`${this.endpoint}?content-group-id=${contentGroupId}`)
	}

	static fetchById (contentItemId: number) {
		return ContentItem.api().get(`${this.endpoint}?content-item-id=${contentItemId}`)
	}
}
