import ServiceProvider from '@/models/ServiceProvider'

export default class ServiceTypeRepo {
	static endpoint = '/api/service-provider'

	static fetchAll () {
		return ServiceProvider.api().get(`${this.endpoint}`)
	}

	static fetchByRegionId (regionId: number) {
		return ServiceProvider.api().get(`${this.endpoint}?region-id=${regionId}`)
	}
}
