import DocumentIllustration from '@/models/DocumentIllustration'

export default class DocumentIllustrationRepo {
	static endpoint = '/api/document-illustration'

	static fetchAll () {
		return DocumentIllustration.api().get(this.endpoint)
	}

	static fetchByServiceTypeRegionProvider (serviceTypeId: number, regionId: number, serviceProviderId: number) {
		return DocumentIllustration.api().get(`${this.endpoint}?service-type-id=${serviceTypeId}&region-id=${regionId}&service-provider-id=${serviceProviderId}`)
	}

	static fetchGenericByServiceTypeRegion (serviceTypeId: number, regionId: number) {
		return DocumentIllustration.api().get(`${this.endpoint}?service-type-id=${serviceTypeId}&region-id=${regionId}`)
	}
}
