<script>
import QrcodeVue from 'qrcode.vue'
import BrandingService from '@/services/BrandingService'
import { isEmpty } from '@/helpers/validate'

export default {
	components: { QrcodeVue },
	data: () => ({
		brandingWeb: {}
	}),
	created: function () {
		this.brandingWeb = BrandingService.get().getWeb()
	},
	computed: {
		url: function () {
			const qrCodeUrl = this.brandingWeb.qrCodeUrl
			return isEmpty(qrCodeUrl) ? `https://${window.location.hostname}` : qrCodeUrl
		}
	}
}
</script>
<template>
	<div class="qr-code">
		<div class="qr-code__instructions">
			<div class="qr-code__instructions--title">
				{{ $t('qr.title') }}
			</div>
			<div class="qr-code__instructions--body" v-html="$t('qr.instructions')" />
		</div>
		<qrcode-vue class="qr-code__image" :value="url" :size="100" level="H" />
	</div>
</template>
<style lang="scss" scoped>
.qr-code {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 1rem 1.5rem;
	width: 100%;
	&__instructions {
		display: flex;
		flex-direction: column;
		margin-right: 1.5rem;
		text-align: left;
		&--title {
			color: var(--on-surface);
			font-size: var(--font-size-subheading);
			font-weight: var(--font-weight-subheading);
			margin-bottom: 1rem;
		}
		&--body {
			font-size: var(--font-size-caption);
			font-weight: var(--font-weight-caption);
		}
	}
	&__image {
		max-height: 100px;
	}
}
</style>
