import Card from '@/models/Card'
import ContentItem from '@/models/ContentItem'
import AgreementRepo from '@/repos/AgreementRepo'
import CardRepo from '@/repos/CardRepo'
import CardContentRepo from '@/repos/CardContentRepo'
import ContentItemRepo from '@/repos/ContentItemRepo'
import ContentDocumentScanRepo from '@/repos/ContentDocumentScanRepo'
import ContentGroupRepo from '@/repos/ContentGroupRepo'
import ContentGroupItemRepo from '@/repos/ContentGroupItemRepo'
import CustomerFieldCategoryRepo from '@/repos/CustomerFieldCategoryRepo'
import CustomerFieldTypeRepo from '@/repos/CustomerFieldTypeRepo'
import CustomerFieldOptionRepo from '@/repos/CustomerFieldOptionRepo'
import CustomContentRepo from '@/repos/CustomContentRepo'
import DocumentFieldCategoryRepo from '@/repos/DocumentFieldCategoryRepo'
import DocumentIllustrationRepo from '@/repos/DocumentIllustrationRepo'
import ServiceTypeRepo from '@/repos/ServiceTypeRepo'
import RegionRepo from '@/repos/RegionRepo'
import ServiceProviderRepo from '@/repos/ServiceProviderRepo'
import ProviderServiceRegionRepo from '@/repos/ProviderServiceRegionRepo'
import { InstanceOf } from '@vuex-orm/core'

export default class StoreService {
	static async populate () {
		await StoreService.populateRegions()

		await StoreService.populateServiceProviders()
		await StoreService.populateProviderServiceRegions()
		await StoreService.populateDocumentIllustrations()

		await StoreService.populateTypesCategories()
		await StoreService.populateCards()
		await StoreService.populateContentGroups()
		await StoreService.populateContent()
	}

	static async populateAgreements () {
		return AgreementRepo.fetchAll()
	}

	static async populateRegions () {
		return RegionRepo.fetchAll()
	}

	static async populateServiceProviders () {
		await ServiceProviderRepo.fetchAll()
	}

	static async populateProviderServiceRegions () {
		await ProviderServiceRegionRepo.fetchAll()
	}

	static async populateDocumentIllustrations () {
		return DocumentIllustrationRepo.fetchAll()
	}

	static async populateCards () {
		await CardRepo.fetchAll()
		let fetchRequests: Array<Promise<any>> = []
		Card.all().forEach((card: InstanceOf<Card>) => {
			fetchRequests.push(CardContentRepo.fetchByCardId(card.id))
			fetchRequests.push(ContentItemRepo.fetchByCardId(card.id))
		})
		return Promise.all(fetchRequests)
	}

	static async populateContentGroups () {
		let fetchRequests: Array<Promise<any>> = []
		ContentItem.query().get()
			.filter((contentItem: InstanceOf<ContentItem>) => contentItem.contentType === 'CONTENT_GROUP')
			.forEach((contentItem: InstanceOf<ContentItem>) => {
				fetchRequests.push(ContentGroupRepo.fetchById(contentItem.contentGroupId))
				fetchRequests.push(ContentGroupItemRepo.fetchByContentGroupId(contentItem.contentGroupId))
				fetchRequests.push(ContentItemRepo.fetchByContentGroupId(contentItem.contentGroupId))
			})
		return Promise.all(fetchRequests)
	}

	static async populateContent () {
		let fetchRequests: Array<Promise<any>> = []
		fetchRequests.push(CustomerFieldTypeRepo.fetchAll())
		ContentItem.query().get()
			.forEach((contentItem: InstanceOf<ContentItem>) => {
				switch (contentItem.contentType) {
					case 'CUSTOMER_FIELD':
						fetchRequests.push(CustomerFieldOptionRepo.fetchByCustomerFieldTypeId(contentItem.customerFieldTypeId))
						break
					case 'CUSTOM_CONTENT':
						fetchRequests.push(CustomContentRepo.fetchById(contentItem.customContentId))
						break
					case 'DOCUMENT_SCAN':
						fetchRequests.push(ContentDocumentScanRepo.fetchById(contentItem.documentScanId))
						break
				}
			})
		return Promise.all(fetchRequests)
	}

	static async populateTypesCategories () {
		let fetchRequests: Array<Promise<any>> = []
		fetchRequests.push(ServiceTypeRepo.fetchAll())
		fetchRequests.push(DocumentFieldCategoryRepo.fetchAll())
		fetchRequests.push(CustomerFieldCategoryRepo.fetchAll())
		return Promise.all(fetchRequests)
	}
}
